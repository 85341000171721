import { useMatomo } from "@datapunt/matomo-tracker-react";
import React, { Fragment, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useStore } from "../../app/stores/store";
import AdvertsBlock from "../adverts/AdvertBlock";
import AmazonAffiliate from "../adverts/AmazonAffiliate";
// import AdvertsBlock from "../adverts/AdvertBlock";
import AdvertOwnBlock from "../blocks/AdvertOwnBlock";
// import BacklinkProductBlock from "../blocks/BacklinkProductBlock";
import LastBlogPosts from "../posts/LastBlogPosts";
import NewsletterSubscribe from "./NewsletterSubscribe";
import SearchBox from "./SearchBox";
import { history } from '../..';

export default function HomePage() {

    const { enableLinkTracking, trackPageView } = useMatomo();
    enableLinkTracking();

    const { blogStore } = useStore();
    const { setPredicate } = blogStore;

    useEffect(() => {        
            trackPageView({
                documentTitle: 'Homepage'
            });
    }, [trackPageView])

    function handleSearch(search: string) {
        // console.log('SUCHBEGRIFF', search);
        setPredicate('searchParam', search);
        history.push("/artikel")
    }

    return (
        <Fragment>
            <Helmet>
                <title>Gesund und Fit durch Fasten | Fit Fasten</title>      
                <meta name="description" content="Plattform mit Themen rund um das Fasten, Fitness und Gesundheit" />
                <meta name="keywords" content="gesundheit, fit sein, fasten, abnehmen, diät" />
                <link rel="canonical" href={window.location.href} />
            </Helmet>
            <Row>
            <Col md={9}>
            <h1>Fit und Gesund durch Fasten</h1>
            <AdvertOwnBlock
                title='Gesund und Fit durch Fasten'
                content='Die Themen und Gesundheit und Abnehmen spielen durch die Corona bedingten Einschränkungen der Bewegung ein große Rolle. Was können Sie dennoch tun, um gesund zu bleiben und Ihr Idealgewicht zu halten oder zu erreichen?'
                image='/banners/fit-fasten-banner.png'
                imageTarget='/erfolgreich-im-internet'
                targets={[
                    { linkText: 'Was ist Fasten?', linkTarget: '/was-ist-fasten' },
                    // { linkText: 'Gesund und Fit durch Fasten', linkTarget: '/erfolgreich-im-internet' },
                    // { linkText: 'Backlinks aufbauen', linkTarget: '/ihre-backlinks' },
                    // { linkText: 'Suchmaschinenoptimierung', linkTarget: '/suchmaschinenoptimierung' },
                    // { linkText: 'Bewerben Sie Ihre Produkte', linkTarget: '/ihre-werbung' },
                ]}
            />

            <AdvertsBlock advertCount={1} advertType={1} />

            <LastBlogPosts articleCount={5} />

            </Col>
            <Col md={3}>
                <SearchBox updateSearch={handleSearch} />
                
                <NewsletterSubscribe />

                <AmazonAffiliate
                    image='https://images-na.ssl-images-amazon.com/images/I/61wzToA8kuL.jpg'
                    text='Umprogrammierung deines Stoffwechsels-Dauerhafte Gewichtsreduktion'
                    link='https://www.amazon.de/dp/3833873566/ref=as_li_ss_tl?dchild=1&keywords=fasten&qid=1624024074&sr=8-3&linkCode=ll1&tag=kayomo-21&linkId=2e22d8c55d8ec6b85eb12ef36441bebf&language=de_DE'
                    title='Wie neugeboren durch Fasten'
                />

                {/* <BacklinkProductBlock /> */}

                {/* <AdvertsBlock advertCount={1} advertType={2} /> */}
            </Col>
            </Row>
        </Fragment>
    )
}