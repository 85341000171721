import { observer } from "mobx-react-lite";
import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import agent from "../../app/api/agent";
import {Helmet} from "react-helmet";
import { IPage } from "../../app/models/content/IPage";
import { Col, Row } from "react-bootstrap";
import { useMatomo } from "@datapunt/matomo-tracker-react";
import AdvertsBlock from "../adverts/AdvertBlock";
import LoadingComponent from "../../app/layout/LoadingComponent";
import NewsletterSubscribe from "../home/NewsletterSubscribe";
import BacklinkProductBlock from "../blocks/BacklinkProductBlock";

export default observer(function ShowPage () {

    const { enableLinkTracking, trackPageView } = useMatomo();

    enableLinkTracking();

    const history = useHistory();
    const [page, setPage] = useState<IPage>();

    const [isLoading, setIsLoading] = useState(true);

    const {slug} = useParams<{slug: string}>();

    useEffect(() => {    
        setIsLoading(true);
        agent.Pages.loadPage(slug).then(response => {
            setPage(response);

            setIsLoading(false);
            
            trackPageView({
                documentTitle: page?.title
            });
        })

    }, [slug, trackPageView])

    function contentClickHandler(e: any)  {
        const targetLink = e.target.closest('a');
        if(!targetLink) return;
        
        if (targetLink.dataset.target !== "ext") {
            e.preventDefault();
            history.push(targetLink.pathname);
            
        } else {
            e.preventDefault();
            window.open(
                targetLink.href,
                '_blank'
              );
        }
      };

    if (isLoading) return <LoadingComponent content='Loading...' />
    // if (isLoading) return null;

    return (
        <Fragment>
            <Helmet>
                <title>{page?.title + ' | ' + process.env.REACT_APP_PORTAL_NAME}</title>
                <meta name="description" content={page?.metaDescription} />
                <meta name="keywords" content={page?.metaKeywords} />         
                <link rel="canonical" href={window.location.href} />
                {page?.noIndex &&
                    <meta name="robots" content="noindex" />
                }
            </Helmet>

            {page?.fullSize && (
                <Row>
                <Col md={12}>
                    <h1>{page?.title}</h1>
                    <hr />
                    <div
                    onClick={contentClickHandler}
                    dangerouslySetInnerHTML={{
                        __html: page != null ? page.content : ''
                    }}></div>
                </Col>
                </Row>
            )}

            {!page?.fullSize && (
                <Row>
                <Col md={9}>
                    <h1>{page?.title}</h1>
                    <hr />

                    <AdvertsBlock advertCount={1} advertType={5} />

                    <div
                    onClick={contentClickHandler}
                    dangerouslySetInnerHTML={{
                        __html: page != null ? page.content : ''
                    }}></div>
                </Col>
                <Col md={3}>
                    <NewsletterSubscribe />

                    <BacklinkProductBlock />
                    
                    <AdvertsBlock advertCount={3} advertType={10} />
                </Col>
                </Row>
            )}

        </Fragment>
    )
})
